import styled from '@emotion/styled'
import { FadeInUp } from 'app/components/Common/FadeInUp'
import { Image, Props as ImageProps } from 'app/components/Common/Image'
import { FlexBox } from 'app/components/Layout/FlexBox'
import React, { memo } from 'react'
import { LazyLoadComponent } from 'react-lazy-load-image-component'

export interface Props {
  description?: string
  image?: ImageProps
  label?: string
  title?: string
}

export const AdvancedIntro = memo(function AdvancedIntro({
  description,
  image,
  label,
  title,
}: Props) {
  return (
    <Container dial={4} row stretch tag="section" wrap>
      <LeftSide dial={4}>
        {label ? (
          <FadeInUp>
            <Label>{label}</Label>
          </FadeInUp>
        ) : null}
        {title ? (
          <FadeInUp>
            <Title dangerouslySetInnerHTML={{ __html: title }} />
          </FadeInUp>
        ) : null}
        {description ? (
          <FadeInUp>
            <Description dangerouslySetInnerHTML={{ __html: description }} />
          </FadeInUp>
        ) : null}
      </LeftSide>

      <RightSide>
        <Background>
          {image ? (
            <LazyLoadComponent>
              <Image {...image} />
            </LazyLoadComponent>
          ) : null}
        </Background>
      </RightSide>
    </Container>
  )
})

const Container = styled(FlexBox)`
  margin: 9.375rem 0 15rem;
  padding: 0 7.292vw;

  @media (max-width: 1199px) {
    margin: 5.625rem 0 9.375rem;
    padding: 0 1.875rem;
  }

  @media (max-width: 1023px) {
    margin-bottom: 7.5rem;
  }
`

const LeftSide = styled(FlexBox)`
  width: 50%;

  @media (max-width: 1023px) {
    display: block;
    width: 100%;
    text-align: center;
  }
`

const Label = styled.div`
  color: ${({ theme }) => theme.colors.variants.neutralDark4};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 1rem;
  font-weight: 300;
  letter-spacing: 0.05rem;
  line-height: 1.125rem;
  margin-bottom: 1.875rem;

  @media (max-width: 1199px) {
    margin-bottom: 1.25rem;
  }
`

const Title = styled.h2`
  max-width: 26.875rem;
  color: ${({ theme }) => theme.colors.variants.neutralDark2};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 3.125rem;
  font-weight: 500;
  line-height: 3.875rem;

  b {
    color: ${({ theme }) => theme.colors.variants.primaryLight};
    font-style: italic;
    font-weight: 500;
  }

  @media (max-width: 1199px) {
    max-width: none;
    font-size: 2.25rem;
    line-height: 2.75rem;
  }
`

const Description = styled.div`
  color: ${({ theme }) => theme.colors.variants.neutralDark4};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 1.125rem;
  font-weight: 300;
  line-height: 2rem;
  margin-top: 5rem;
  padding-left: 6.5625rem;
  position: relative;
  &:before {
    content: '';
    width: 4.6875rem;
    height: 0.125rem;
    background: ${({ theme }) => theme.colors.variants.primaryLight};
    position: absolute;
    top: 1rem;
    left: 0;
  }

  @media (max-width: 1199px) {
    font-size: 1rem;
    margin-top: 3.75rem;
    padding-left: 5.625rem;
    &:before {
      width: 3.75rem;
      top: 0.9375rem;
    }
  }

  @media (max-width: 1023px) {
    margin-top: 2.5rem;
    padding-left: 0;
    &:before {
      display: none;
    }
  }
`

const RightSide = styled.div`
  width: 50%;
  padding-left: 7.292vw;
  position: relative;
  &:after {
    content: '';
    width: 17.375rem;
    height: 18.1875rem;
    background: ${({ theme }) => theme.colors.variants.neutralLight3};
    position: absolute;
    bottom: 0;
    left: 7.292vw;
    z-index: -1;
  }

  @media (max-width: 1199px) {
    padding-left: 1.875rem;
    &:after {
      width: 13.75rem;
      height: 14.375rem;
      left: 1.875rem;
    }
  }

  @media (max-width: 1023px) {
    display: none;
  }
`

const Background = styled.div`
  width: calc(100% - 1.875rem);
  height: 73.3vh;
  margin: 0 0 1.875rem 1.875rem;
  position: relative;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
  }
`
