import styled from '@emotion/styled'
import { motion } from 'framer-motion'
import React, { memo } from 'react'
import { useInView } from 'react-intersection-observer'

export interface Props {
  icon?: string
  image?: string
  label?: string
}

export const Service = memo(function Service({ icon, image, label }: Props) {
  const [ref, isVisible] = useInView({ threshold: 0.25 })
  const variants = {
    visible: {
      opacity: 1,
      scale: 1,
    },
    hidden: {
      opacity: 0,
      scale: 0.8,
    },
  }

  return (
    <Container
      ref={ref}
      animate={isVisible ? 'visible' : 'hidden'}
      transition={{
        duration: 0.5,
      }}
      variants={variants}
    >
      {image ? (
        <Background>
          <Image style={{ backgroundImage: `url(${image})` }} />
        </Background>
      ) : null}

      <Wrapper>
        {icon ? <SVG src={icon} width="40" height="30" alt={label} /> : null}
        {label ? <Label>{label}</Label> : null}
      </Wrapper>
    </Container>
  )
})

const Container = styled(motion.div)`
  width: calc(25% - 1.875rem);
  background: ${({ theme }) => theme.colors.variants.primaryLight};
  margin: 1.875rem 1.875rem 0 0;
  padding-bottom: calc(25% - 1.875rem);
  position: relative;
  &:nth-of-type(3),
  &:nth-of-type(4) {
    width: calc(50% - 1.875rem);
  }

  @media (max-width: 1023px) {
    width: calc(25% - 0.75rem);
    margin: 0.75rem 0.75rem 0 0;
    padding-bottom: calc(25% - 0.75rem);
    &:nth-of-type(3),
    &:nth-of-type(4) {
      width: calc(50% - 0.75rem);
    }
  }

  @media (max-width: 767px) {
    width: 100%;
    margin-right: 0;
    padding-bottom: 40%;
    &:nth-of-type(3),
    &:nth-of-type(4) {
      width: 100%;
    }
  }
`

const Background = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  &:before {
    content: '';
    width: 100%;
    height: 100%;
    background: ${({ theme }) => theme.colors.variants.neutralDark1};
    opacity: 0.6;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
  }
`

const Image = styled.div`
  width: 100%;
  height: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: absolute;
  top: 0;
  left: 0;
`

const Wrapper = styled.div`
  position: absolute;
  top: 50%;
  right: 1.875rem;
  left: 1.875rem;
  text-align: center;
  transform: translateY(-50%);
  z-index: 2;
`

const SVG = styled.img`
  width: auto;
  height: 1.875rem;
  margin-bottom: 1.25rem;

  @media (max-width: 767px) {
    height: 1.5rem;
    margin-bottom: 1rem;
  }
`

const Label = styled.span`
  display: block;
  color: ${({ theme }) => theme.colors.variants.neutralLight4};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 1.0625rem;
  line-height: 1.375rem;

  @media (max-width: 1199px) {
    font-size: 1rem;
  }
`
