import styled from '@emotion/styled'
import { Button, Props as ButtonProps } from 'app/components/Common/Button'
import { FadeInUp } from 'app/components/Common/FadeInUp'
import { Line } from 'app/components/Common/Line'
import { FlexBox } from 'app/components/Layout/FlexBox'
import React, { memo } from 'react'

import { Props as ServiceProps, Service } from './Service'

export interface Props {
  cta?: ButtonProps
  label?: string
  services?: ServiceProps[]
  title?: string
}

export const ServicesPreview = memo(function ServicesPreview({
  cta,
  label,
  services,
  title,
}: Props) {
  if (!services) {
    return null
  }

  return (
    <Container>
      {label ? (
        <FadeInUp>
          <Label>{label}</Label>
        </FadeInUp>
      ) : null}
      {title ? (
        <FadeInUp>
          <Title dangerouslySetInnerHTML={{ __html: title }} />
          <Line className="line" variant="compact" />
        </FadeInUp>
      ) : null}
      {cta ? (
        <FadeInUp>
          <Button {...cta} variant="simple" />
        </FadeInUp>
      ) : null}

      <Services row wrap stretch>
        {services.map((item, index) => (
          <Service key={index} {...item} />
        ))}
      </Services>
    </Container>
  )
})

const Container = styled.section`
  margin: 12.5rem 0;
  padding: 0 7.292vw;
  text-align: center;

  .line {
    margin-right: auto;
    margin-left: auto;
  }

  @media (max-width: 1199px) {
    margin: 7.5rem 0;
    padding: 0 1.875rem;
  }

  @media (max-width: 767px) {
    margin: 5.625rem 0;
  }
`

const Label = styled.div`
  color: ${({ theme }) => theme.colors.variants.neutralDark4};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 1rem;
  font-weight: 300;
  letter-spacing: 0.05rem;
  line-height: 1.125rem;
  margin-bottom: 1.875rem;

  @media (max-width: 1199px) {
    margin-bottom: 1.25rem;
  }
`

const Title = styled.h2`
  color: ${({ theme }) => theme.colors.variants.neutralDark2};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 3.125rem;
  font-weight: 500;
  line-height: 3.875rem;

  b {
    color: ${({ theme }) => theme.colors.variants.primaryLight};
    font-style: italic;
    font-weight: 500;
  }

  @media (max-width: 1199px) {
    font-size: 2.25rem;
    line-height: 2.75rem;
  }
`

const Services = styled(FlexBox)`
  margin: 1.875rem -1.875rem 0 0;

  @media (max-width: 1023px) {
    margin: 1.875rem -0.75rem 0 0;
  }

  @media (max-width: 767px) {
    margin-right: 0;
  }
`
