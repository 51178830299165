import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { ChevronLeft, ChevronRight } from 'app/components/Icons'
import { FlexBox } from 'app/components/Layout/FlexBox'
import { theme } from 'app/theme'
import { rgba } from 'emotion-rgba'
import React, { memo } from 'react'

export interface Props {
  direction?: 'L' | 'R'
  onClick?: (e: any) => void
  variant?: Variant
}

export const Arrow = memo(function Arrow({
  direction = 'L',
  onClick,
  variant = 'default',
}: Props) {
  return (
    <Container dial={5} onClick={onClick} variant={variant}>
      {direction === 'L' ? <ChevronLeft /> : <ChevronRight />}
    </Container>
  )
})

const Container = styled(FlexBox)<ContainerProps>`
  width: 4.625rem;
  height: 4.625rem;
  background: ${({ theme }) => theme.colors.variants.neutralLight4};
  cursor: pointer;
  &:hover {
    svg {
      stroke: ${({ theme }) => theme.colors.variants.neutralDark2};
    }
  }

  svg {
    width: auto;
    height: 1.25rem;
    fill: none;
    stroke: ${({ theme }) => theme.colors.variants.primaryLight};
    stroke-width: 2;
    transition: 0.2s ease-in-out;
  }

  ${({ variant }) => {
    switch (variant) {
      case 'small':
        return css`
          width: 2.5rem;
          height: 2.5rem;
          &:hover {
            svg {
              stroke: ${theme.colors.variants.neutralDark2};
            }
          }

          @media (max-width: 1199px) {
            width: 2.5rem !important;
            height: 2.5rem !important;
          }
        `
    }
  }}

  @media (max-width: 1199px) {
    width: 3.75rem;
    height: 3.75rem;
  }
`

interface ContainerProps {
  variant: Variant
}

export type Variant = 'default' | 'small'
