import styled from '@emotion/styled'
import { Button, Props as ButtonProps } from 'app/components/Common/Button'
import { FadeInUp } from 'app/components/Common/FadeInUp'
import { Image, Props as ImageProps } from 'app/components/Common/Image'
import { Line } from 'app/components/Common/Line'
import { FlexBox } from 'app/components/Layout/FlexBox'
import React, { memo } from 'react'
import { LazyLoadComponent } from 'react-lazy-load-image-component'

export interface Props {
  cta?: ButtonProps
  description?: string
  images?: ImageProps[]
  label?: string
  title?: string
}

export const RoomsPreview = memo(function RoomsPreview({
  cta,
  description,
  images,
  label,
  title,
}: Props) {
  return (
    <Container dial={5} row stretch tag="section" wrap>
      <LeftSide>
        {images && images[0] ? (
          <LazyLoadComponent>
            <Image {...images[0]} />
          </LazyLoadComponent>
        ) : null}
      </LeftSide>

      <CenterSide>
        {label ? (
          <FadeInUp>
            <Label>{label}</Label>
          </FadeInUp>
        ) : null}
        {title ? (
          <FadeInUp>
            <Title dangerouslySetInnerHTML={{ __html: title }} />
            <Line className="line" variant="compact-invert" />
          </FadeInUp>
        ) : null}
        {description ? (
          <FadeInUp>
            <Description dangerouslySetInnerHTML={{ __html: description }} />
          </FadeInUp>
        ) : null}
        {cta ? <Button {...cta} variant="dark" /> : null}
      </CenterSide>

      <RightSide>
        {images && images[1] ? (
          <LazyLoadComponent>
            <Image {...images[1]} />
          </LazyLoadComponent>
        ) : null}
      </RightSide>
    </Container>
  )
})

const Container = styled(FlexBox)`
  margin: 15rem 0 12.5rem;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
  }

  .line {
    margin-right: auto;
    margin-left: auto;
  }

  @media (max-width: 1199px) {
    margin: 7.5rem 0 5.625rem;
  }
`

const LeftSide = styled.div`
  width: 28%;
  background: ${({ theme }) => theme.colors.variants.neutralLight3};
  margin-right: 1.875rem;
  position: relative;

  @media (max-width: 1199px) {
    width: 18%;
  }

  @media (max-width: 1023px) {
    display: none;
  }
`

const CenterSide = styled.div`
  flex: 1;
  background: ${({ theme }) => theme.colors.variants.primaryLight};
  color: ${({ theme }) => theme.colors.variants.neutralLight4};
  padding: 6rem 5rem;
  text-align: center;

  @media (max-width: 1023px) {
    padding: 5rem 1.875rem;
  }
`

const Label = styled.div`
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 1rem;
  font-weight: 300;
  letter-spacing: 0.05rem;
  line-height: 1.125rem;
  margin-bottom: 1.875rem;

  @media (max-width: 1199px) {
    margin-bottom: 1.25rem;
  }
`

const Title = styled.h2`
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 3.125rem;
  font-weight: 500;
  line-height: 3.875rem;

  b {
    font-style: italic;
    font-weight: 500;
  }

  @media (max-width: 1199px) {
    font-size: 2.25rem;
    line-height: 2.75rem;
  }
`

const Description = styled.div`
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 1.125rem;
  font-weight: 300;
  line-height: 2rem;
  margin-top: 3.75rem;

  @media (max-width: 1199px) {
    font-size: 1rem;
    margin-top: 2.5rem;
  }
`

const RightSide = styled.div`
  width: 28%;
  background: ${({ theme }) => theme.colors.variants.neutralLight3};
  margin-left: 1.875rem;
  position: relative;

  @media (max-width: 1199px) {
    width: 18%;
  }

  @media (max-width: 1023px) {
    display: none;
  }
`
