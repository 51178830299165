import 'keen-slider/keen-slider.min.css'

import styled from '@emotion/styled'
import { Arrow } from 'app/components/Common/Arrow'
import { Button, Props as ButtonProps } from 'app/components/Common/Button'
import { FadeInUp } from 'app/components/Common/FadeInUp'
import { FlexBox } from 'app/components/Layout/FlexBox'
import { useKeenSlider } from 'keen-slider/react'
import React, { memo, useEffect, useRef, useState } from 'react'

import { Props as SlideProps, Slide } from './Slide'

const AUTOPLAY_TIMER = 5000

export interface Props {
  cta?: ButtonProps
  description?: string
  label?: string
  slides: SlideProps[]
  title?: string
}

export const OffersSlider = memo(function OffersSlider({
  cta,
  description,
  label,
  slides,
  title,
}: Props) {
  if (slides.length < 1) {
    return null
  }

  const [currentSlide, setCurrentSlide] = useState(0)
  const [opacities, setOpacities] = React.useState<number[]>([])
  const timer = useRef<any>()

  const [sliderRef, instanceRef] = useKeenSlider({
    drag: false,
    loop: slides.length > 1 ? true : false,
    slides: slides.length,
    animationEnded(s) {
      setCurrentSlide(s.track.details.rel)
    },
    detailsChanged(s) {
      const newOpacities = s.track.details.slides.map((slide) => slide.portion)
      slides.length > 1 ? setOpacities(newOpacities) : null
    },
    breakpoints: {
      '(max-width: 767px)': {
        drag: true,
      },
    },
  })

  useEffect(() => {
    timer.current = setInterval(() => {
      if (instanceRef) {
        instanceRef.current?.next()
      }
    }, AUTOPLAY_TIMER)

    return () => {
      clearInterval(timer.current)
    }
  }, [currentSlide, instanceRef, sliderRef])

  return (
    <Container dial={4} row stretch tag="section" wrap>
      <LeftSide dial={4}>
        {label ? (
          <FadeInUp>
            <Label>{label}</Label>
          </FadeInUp>
        ) : null}
        {title ? (
          <FadeInUp>
            <Title dangerouslySetInnerHTML={{ __html: title }} />
          </FadeInUp>
        ) : null}
        {description ? (
          <FadeInUp>
            <Description dangerouslySetInnerHTML={{ __html: description }} />
          </FadeInUp>
        ) : null}
        {cta ? (
          <FadeInUp>
            <Button {...cta} />
          </FadeInUp>
        ) : null}
      </LeftSide>

      <RightSide>
        <Slider ref={sliderRef}>
          {slides.map((item, index) => (
            <Slide
              key={index}
              style={{
                opacity: opacities[index],
                zIndex: opacities[index],
              }}
              {...item}
            />
          ))}
        </Slider>

        {slides.length > 1 ? (
          <Arrows row>
            <Arrow
              onClick={(e) =>
                e.stopPropagation() || instanceRef.current?.prev()
              }
            />
            <Arrow
              direction="R"
              onClick={(e) =>
                e.stopPropagation() || instanceRef.current?.next()
              }
            />
          </Arrows>
        ) : null}
      </RightSide>
    </Container>
  )
})

const Container = styled(FlexBox)``

const LeftSide = styled(FlexBox)`
  width: 50%;
  padding: 6.25rem 11.458vw 6.25rem 7.292vw;

  @media (max-width: 1199px) {
    padding: 5rem 1.875rem 5rem 1.875rem;
  }

  @media (max-width: 1023px) {
    display: block;
    width: 100%;
    padding-bottom: 0;
    text-align: center;
  }
`

const Label = styled.div`
  color: ${({ theme }) => theme.colors.variants.neutralDark4};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 1.25rem;
  font-weight: 300;
  letter-spacing: 0.0625rem;
  line-height: 1.125rem;
  opacity: 0.4;
  margin-bottom: 1.875rem;

  @media (max-width: 1199px) {
    font-size: 1rem;
    margin-bottom: 1.25rem;
  }
`

const Title = styled.h2`
  color: ${({ theme }) => theme.colors.variants.neutralDark2};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 3.125rem;
  font-weight: 500;
  line-height: 3.875rem;

  b {
    color: ${({ theme }) => theme.colors.variants.primaryLight};
    font-style: italic;
    font-weight: 500;
  }

  @media (max-width: 1199px) {
    font-size: 2.25rem;
    line-height: 2.75rem;
  }
`

const Description = styled.div`
  color: ${({ theme }) => theme.colors.variants.neutralDark4};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 1.0625rem;
  font-weight: 300;
  line-height: 2rem;
  margin-top: 3.75rem;
  padding-left: 5.5rem;
  position: relative;
  &:before {
    content: '';
    width: 3rem;
    height: 0.125rem;
    background: ${({ theme }) => theme.colors.variants.primaryLight};
    position: absolute;
    top: 1rem;
    left: 0;
  }

  @media (max-width: 1199px) {
    font-size: 1rem;
    margin-top: 2.5rem;
    padding-left: 4.875rem;
  }

  @media (max-width: 1023px) {
    margin-top: 1.875rem;
    padding-left: 0;
    &:before {
      display: none;
    }
  }
`

const RightSide = styled.div`
  width: 50%;
  background: ${({ theme }) => theme.colors.variants.neutralLight3};
  padding: 6.25rem 7.292vw 6.25rem 0;
  position: relative;

  @media (max-width: 1199px) {
    padding: 1.875rem 1.875rem 1.875rem 0;
  }

  @media (max-width: 1023px) {
    display: none;
  }
`

const Slider = styled.div`
  display: flex;
  height: 100%;
  min-height: 37.5rem;
  position: relative;

  @media (max-width: 1199px) {
    min-height: 28.125rem;
  }
`

const Arrows = styled(FlexBox)`
  justify-content: center;
  position: absolute;
  bottom: 6.25rem;
  right: 7.292vw;
  left: 0;
  z-index: 2;

  @media (max-width: 1199px) {
    bottom: 1.875rem;
    right: 1.875rem;
  }
`
