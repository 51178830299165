import styled from '@emotion/styled'
import { Image, Props as ImageProps } from 'app/components/Common/Image'
import React, { memo } from 'react'
import { LazyLoadComponent } from 'react-lazy-load-image-component'

export interface Props {
  className?: string
  image?: ImageProps
  title?: string
  websiteURL?: string
}

export const Hotel = memo(function Hotel({
  className,
  image,
  title,
  websiteURL,
}: Props) {
  return (
    <Container
      className={className}
      href={websiteURL || undefined}
      rel="noopener"
      target="_blank"
    >
      {image ? (
        <LazyLoadComponent>
          <Image {...image} />
        </LazyLoadComponent>
      ) : null}

      {title ? <Title className="hotel-title">{title}</Title> : null}
    </Container>
  )
})

const Container = styled.a`
  background: ${({ theme }) => theme.colors.variants.neutralLight3};
  overflow: hidden;
  padding-bottom: 17.1%;
  position: relative;
  &:hover {
    img {
      transform: scale(1.1);
    }
    .hotel-title {
      &:before {
        width: 100%;
      }
    }
  }
  &:before {
    content: '';
    width: 100%;
    height: 100%;
    backface-visibility: hidden;
    background: ${({ theme }) => theme.colors.variants.neutralDark1};
    opacity: 0.6;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
    transition: 0.2s ease-in-out !important;
  }

  @media (max-width: 1023px) {
    padding-bottom: 26%;
  }

  @media (max-width: 767px) {
    padding-bottom: 42%;
  }
`

const Title = styled.span`
  color: ${({ theme }) => theme.colors.variants.neutralLight4};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 1rem;
  line-height: 2rem;
  position: absolute;
  bottom: 1.875rem;
  left: 50%;
  text-align: center;
  transition: 0.2s ease-in-out;
  transform: translateX(-50%);
  z-index: 2;
  &:before {
    content: '';
    width: 1.875rem;
    height: 0.125rem;
    background: ${({ theme }) => theme.colors.variants.primaryLight};
    position: absolute;
    bottom: 0;
    left: 50%;
    transition: 0.2s ease-in-out;
    transform: translateX(-50%);
  }
`
