import styled from '@emotion/styled'
import React, { memo } from 'react'

export interface Props {
  label: string
  x_axis: number
  y_axis: number
}

export const Poi = memo(function Poi({ label, x_axis, y_axis }: Props) {
  return (
    <Container style={{ top: `${y_axis}%`, left: `${x_axis}%` }}>
      <Tooltip>
        <Label>{label}</Label>
      </Tooltip>
    </Container>
  )
})

const Container = styled.div`
  width: 0.875rem;
  height: 0.875rem;
  background: ${({ theme }) => theme.colors.variants.primaryLight};
  border: 0.125rem solid ${({ theme }) => theme.colors.variants.neutralLight4};
  border-radius: 50%;
  cursor: pointer;
  position: absolute;
  &.active {
    &:after {
      transform: translate(-50%, -50%) scale(1);
    }
  }
`

const Tooltip = styled.div`
  background: ${({ theme }) => theme.colors.variants.neutralLight4};
  padding: 0.5625rem 0.625rem;
  position: absolute;
  bottom: calc(100% - 3.5rem);
  left: 50%;
  transform: translateX(-50%);
`

const Label = styled.div`
  color: ${({ theme }) => theme.colors.variants.neutralDark2};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 0.8125rem;
  line-height: 1rem;
  text-transform: uppercase;
  white-space: nowrap;
`
